jQuery(document).ready(function($) {
	$('.bg-tab-slider-tabs-wrapper button').on('click', function() {
		var tabID = $(this).attr('data-tab');
		// console.log('tab id = ' + tabID);
		// Remove active state from bg images, content sections, & side tab labels
		$('.bg-tab-slide-single').removeClass('active');
		$('.bg-tab-slider-tab-button').removeClass('active');
		// Add active class to bg image, content section & side label matching this id
		$(".bg-tab-slide-single[data-slide='" + tabID + "']").addClass('active');
		$(this).addClass('active');
	});
}); /* end of as page load scripts */